var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "div",
        { staticClass: "tr table-row-data" },
        _vm._l(_vm.performancePeriodHeadings, function (period, index) {
          return _c(
            "div",
            { key: index, staticClass: "td align-center column-date" },
            [_c("span", [_vm._v(_vm._s(period))])]
          )
        }),
        0
      ),
      _vm._l(
        _vm.performanceDataWithPrefills,
        function (managerDataRow, rowIndex) {
          return _c(
            "div",
            {
              key: managerDataRow.name + "_" + rowIndex,
              staticClass: "tr table-row-data",
            },
            _vm._l(managerDataRow.performance, function (item, itemIndex) {
              return _c(
                "div",
                { key: itemIndex, staticClass: "td data-input" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _vm.inputIssueMessage.display &&
                      _vm.inputIssueMessage.rowIndex === rowIndex &&
                      _vm.inputIssueMessage.itemIndex === itemIndex
                        ? _c("span", { staticClass: "warning-message" }, [
                            _vm._v(
                              " " + _vm._s(_vm.inputIssueMessage.message) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("text-input-with-formatted-overlay", {
                    class: { isQWarning: _vm.qWarningCheck(item, rowIndex) },
                    attrs: {
                      formatType: "performance",
                      "debug-data": item,
                      "is-date-in-future": _vm.isDateInFuture(
                        item.effectiveDate
                      ),
                      "q-warning": _vm.qWarningCheck(item, rowIndex),
                      "status-class": _vm.getStatusClass(item),
                      tabindex: _vm.isDateInFuture(item.effectiveDate)
                        ? -1
                        : itemIndex + 1,
                      value: _vm.getTickerValueAsNumber(item.tickerValue),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updateValue(
                          item,
                          $event.target.value,
                          rowIndex,
                          itemIndex,
                          item.performancePeriodTag
                        )
                      },
                      focus: function ($event) {
                        return _vm.highlightManager(rowIndex)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }