






































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { getStatusClass } from '@/utils';
import TextInputWithFormattedOverlay from '@/components/inputs/TextInputWithFormattedOverlay.vue';
import PerformanceType from '@/models/Performance';
import PerformanceItem from '@/models/PerformanceItem';
import {formatNumberToDecimal} from '@/utils/formatNumberToDecimal';

@Component({
    components: {
        TextInputWithFormattedOverlay,
    },
})
export default class PerformanceTable extends Vue {
    @Prop({type: Array, required: true}) performancePeriodHeadings!: Array<string>;

    @Prop({type: Array, required: true}) performanceDataWithPrefills!: Array<PerformanceType>;

    @Prop({type: Array, required: true}) originalPerformanceData!: Array<PerformanceType>;


    qWarningCheck (item: PerformanceItem, rowIndex: number): boolean {
        if (!item.meta.isQuarter || item.tickerValue === '') return false;

        const monthData: Array<number> = [];
        this.performanceDataWithPrefills[rowIndex].performance.forEach((m) => {
            if (!m.meta.isQuarter && m.meta.qIndex === item.meta.qIndex && m.tickerValue !== '') {
                monthData.push(m.tickerValue / 100 + 1);
            }
        });

        if (monthData.length < 3) return false;

        const refRet = this.calcQuarterCompoundInterest(monthData);

        return refRet > item.tickerValue + 0.1 || refRet < item.tickerValue - 0.1;
    }

    public calcQuarterCompoundInterest (mValues: Array<number>): number {
        let q1CompoundResult = mValues.reduce((a: number, b: number) => a * b) - 1;
        return Number(q1CompoundResult * 100);
    }

    public originalData: Array<PerformanceType> = [];

    public getStatusClass = getStatusClass;

    public getTickerValueAsNumber (tickerValue: number | ''): number | string {
        return tickerValue === '' ? tickerValue : formatNumberToDecimal(tickerValue, {minimumFractionDigits: 1});
    }

    public inputIssueMessage = {
        message: '',
        rowIndex: -1,
        itemIndex: -1,
        display: false,
    };

    public isDateInFuture (day: string): boolean {
        return dayjs(day).isAfter(new Date(), 'day');
    }

    @Emit('highlightManagerInFocus')
    public highlightManager (rowIndex: number): void {
        this.$nextTick(() => {
            return rowIndex;
        });
    }

    public updateValue (
        item: PerformanceItem<string | number>,
        newValue: string,
        rowIndex: number,
        itemIndex: number,
        performancePeriodTag: string
    ): void {
        let parsedValue: number | string = newValue;
        // check for non acceptable inputs
        const regexFloat = new RegExp(/^[+-]?\d+(\.\d+)?$/);
        const valueBeforeUpdate = this.performanceDataWithPrefills[rowIndex].performance[itemIndex].tickerValue;
        newValue = newValue.replace('%', '');

        if (newValue !== '') {
            if (regexFloat.test(newValue)) {
                parsedValue = typeof newValue !== 'number' ? parseFloat(newValue) : newValue;
                if (parsedValue > 10000 || parsedValue < -100) {
                    this.inputIssueMessage = {
                        message: 'Value must be within range +10,000/-100',
                        rowIndex,
                        itemIndex,
                        display: true,
                    };

                    setTimeout(() => {
                        this.inputIssueMessage = {
                            message: '',
                            rowIndex: -1,
                            itemIndex: -1,
                            display: false,
                        };
                    }, 1500);

                    parsedValue = valueBeforeUpdate;
                }
            } else {
                parsedValue = valueBeforeUpdate;
            }
        }

        let edited = true;
        const matchesOriginalValue = this.performanceDataWithPrefills[rowIndex].performance.find((assetValue: PerformanceItem<string | number>) => {
            const isSameRow = assetValue.rowID === item.rowID;
            const isSameColumn = assetValue.performancePeriodTag === performancePeriodTag;
            const isSameValue = assetValue.tickerValue === parsedValue;
            return isSameRow && isSameColumn && isSameValue;
        });

        if (matchesOriginalValue || (item.rowID === null && parsedValue === '')) {
            edited = false;
        }

        const newItem = new PerformanceItem<number | string>(
            item.effectiveDate,
            item.performancePeriodTag,
            item.rowID,
            item.tickerID,
            parsedValue,
            item.type,
            parsedValue === '' && item.rowID !== null,
            {
                new: item.meta.new,
                edited: edited,
                show: true,
                isNewColumn: false,
                qIndex: 0,
                isQuarter: item.performancePeriodTag.charAt(0) === 'Q',
            },
            item.benchmarkId
        );

        this.$emit('updatePerformanceValue', {
            item: newItem,
            rowIndex,
            itemIndex,
        });
    }

    //#endregion
}
