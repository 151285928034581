










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import {  IPartPeriodPerformanceRow} from '@/interfaces/performance/IPartPeriodPerformance';
import isBetween from 'dayjs/plugin/isBetween';
import {formatNumberToPercent} from '@/utils/formatNumberToPercent';
import PerformanceType, { IPPP } from '@/models/Performance';

dayjs.extend(isBetween);


@Component({
    components: {
        DatePicker,
    },
})
export default class PartPeriodPerformance extends Vue {


  @Prop({type: Boolean, required: true}) isPPPFetchingData!: boolean;


  @Prop({type: Object, required: true}) pppEntity!: PerformanceType | null;

  @Prop({type: String, required: true}) pppAPIValidationErrorMessage: string;


  public isFuture (date: Date): boolean {
      return dayjs(date).isAfter(dayjs(), 'day'); // true if date is after today
  }

  get pppEntityId (): number|null {
      return this.pppEntity ? this.pppEntity.id : null;
  }

  get pppItems (): IPPP[] {
      return  this.pppEntity ? this.pppEntity.partPeriodPerformances : [];
  }


  created ():void {
      this.initRows();
  }

    count = 0;

    partPeriodPerformances: IPartPeriodPerformanceRow[] = [];

    updateActionFlag (rowCount:number):void {
        const rowIndex = this.getRowIndexById(rowCount);
        if (rowIndex === -1) return;
        if (this.partPeriodPerformances[rowIndex].actionFlag !== 'I') {
            Vue.set(this.partPeriodPerformances[rowIndex], 'actionFlag', 'U');
        }
    }

    formatInput (rowCount: number, value: string):void {
        const rowIndex = this.getRowIndexById(rowCount);
        if (rowIndex === -1) return;

        // Remove any non-numeric characters from the input
        const sanitizedValue = value.replace(/[^0-9.-]/g, '');

        // Format the value to 1 decimal place, do not round
        const tickerValue = Number(parseFloat(sanitizedValue).toFixed(1));

        // Update the input with the formatted value
        Vue.set(this.partPeriodPerformances, rowIndex, {
            ...this.partPeriodPerformances[rowIndex],
            tickerValue
        });

        // update the action flag to U if the row is not new
        if (this.partPeriodPerformances[rowIndex].actionFlag !== 'I') {
            Vue.set(this.partPeriodPerformances[rowIndex], 'actionFlag', 'U');
        }


    }

    numberValueFormatter (value:number):string {
        const valueDividedBy100 = value / 100;
        return formatNumberToPercent(valueDividedBy100, { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }

    getRowIndexById (rowCount:number):number {
        return this.partPeriodPerformances.findIndex((row) => row.rowCount === rowCount);
    }


    deleteRowById (rowCount:number):void {
        const rowIndex = this.getRowIndexById(rowCount);
        if (rowIndex === -1) return;

        this.partPeriodPerformances[rowIndex].actionFlag = 'D';
        this.partPeriodPerformances[rowIndex].isDelete = true;

        // delete the row if it is new and not yet saved to db
        if (this.partPeriodPerformances[rowIndex].rowId === null) {
            Vue.delete(this.partPeriodPerformances, rowIndex);
        }

    }

    initRows ():void {
        const cloneObj = JSON.parse(JSON.stringify(this.pppItems));
        this.partPeriodPerformances = cloneObj.map((item: IPPP) => {
            this.count += 1;
            return {
                dates: [item.startDate, item.effectiveDate],
                tickerValue: item.tickerValue,
                rowCount: this.count,
                rowId: item.rowID,
                tickerId: item.tickerID ?? cloneObj.pppTickerId ?? null,
                actionFlag: 'N',
            };
        });
        if (this.partPeriodPerformances.length === 0) {
            this.addRow();
        }
    }

    addRow ():void {
        this.count += 1;
        this.partPeriodPerformances.push({
            dates: ['', ''],
            tickerValue: 0,
            rowCount: this.count,
            rowId: null,
            tickerId: this.pppEntity.pppTickerId ?? null,
            isDelete: false,
            actionFlag: 'I',
        });
    }

    isValidationFailed = false;


    public close (): void {
        this.isValidationFailed = false;
        this.$emit('close');
    }

    get listFormErrors (): string[] {
        return [...this.formErrors, this.pppAPIValidationErrorMessage];
    }

    formErrors: string[] = [];

    public validate (): boolean {
        const pppItems = this.partPeriodPerformances.filter((row) => row.actionFlag !== 'D');

        const areDatesPopulated = pppItems.every((row) => {
            return row.dates[0] && row.dates[1];
        });

        // make sure that there is no overlap for any of the row dates
        const doDatesOverlap = pppItems.some((row) => {
            const startDate = dayjs(row.dates[0]);
            const endDate = dayjs(row.dates[1]);
            return pppItems.some((row2) => {
                const startDate2 = dayjs(row2.dates[0]);
                const endDate2 = dayjs(row2.dates[1]);
                return row.rowCount !== row2.rowCount && (startDate.isBetween(startDate2, endDate2, 'day', '()') || endDate.isBetween(startDate2, endDate2, 'day', '()'));
            });
        });


        const isTickerValueValid = pppItems.every((row) => {
            return !isNaN(row.tickerValue);
        });

        const isTickerValueRangeValid = pppItems.every((row) => {
            return row.tickerValue >= -10000 && row.tickerValue <= 10000;
        });

        this.formErrors = [];

        if (!areDatesPopulated) {
            this.formErrors.push('Please select a start and end date for each part period.');
        } if (doDatesOverlap) {
            this.formErrors.push('Please ensure that the dates do not overlap.');
        } if (!isTickerValueValid) {
            this.formErrors.push('Please ensure that the percentage is a number.');
        }
        if (!isTickerValueRangeValid) {
            this.formErrors.push('Please ensure that the percentage is between -10,000 and 10,000.');
        }

        return areDatesPopulated && !doDatesOverlap && isTickerValueValid && isTickerValueRangeValid;
    }


    public save (): void {
        this.isValidationFailed = false;
        if (!this.validate()) {
            this.isValidationFailed = true;
            return;
        }

        //filter out untouched rows
        const payload: IPartPeriodPerformanceRow[] = this.partPeriodPerformances.filter((row) => row.actionFlag !== 'N');

        // set deleted rows tickerValue to null - will trigger delete in the backend
        payload.forEach((row) => {
            if (row.actionFlag === 'D') {
                row.tickerValue = null;
            }
        });

        if (payload.length === 0) {
            this.isValidationFailed = true;
            this.formErrors.push('No changes to save.');
            return;
        }

        this.$emit('save', payload);
    }
}
