var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", appear: "" } }, [
    _c("div", { staticClass: "modal" }, [
      _c("div", { staticClass: "modal-background", on: { click: _vm.close } }),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("h1", [
            _vm._v("Part Period Performance - " + _vm._s(_vm.pppEntity.name)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            !_vm.pppEntityId
              ? _c("p", { staticClass: "invalid-text" }, [
                  _vm._v("Invalid entity selected, please check and try again"),
                ])
              : [
                  _c(
                    "transition-group",
                    { attrs: { name: "fade" } },
                    [
                      _c("div", { key: "title", staticClass: "labels" }, [
                        _c("p", [_vm._v("Part Period")]),
                        _c("p", [_vm._v("Performance")]),
                      ]),
                      _vm._l(
                        _vm.partPeriodPerformances,
                        function (partPeriodPerformance) {
                          return _c(
                            "div",
                            {
                              key: partPeriodPerformance.rowCount,
                              staticClass: "input-row",
                              class: partPeriodPerformance.actionFlag,
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  valueType: "YYYY-MM-DD",
                                  format: "DD/MM/YYYY",
                                  range: "",
                                  clearable: false,
                                  "range-separator": " to ",
                                  "disabled-date": _vm.isFuture,
                                  placeholder: "Choose start and end dates",
                                  disabled:
                                    partPeriodPerformance.actionFlag === "D" ||
                                    _vm.isPPPFetchingData,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateActionFlag(
                                      partPeriodPerformance.rowCount
                                    )
                                  },
                                },
                                model: {
                                  value: partPeriodPerformance.dates,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      partPeriodPerformance,
                                      "dates",
                                      $$v
                                    )
                                  },
                                  expression: "partPeriodPerformance.dates",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "sr-only",
                                  attrs: {
                                    for:
                                      "tickerValue-input" +
                                      partPeriodPerformance.rowCount,
                                  },
                                },
                                [_vm._v("Enter Value:")]
                              ),
                              _c("input", {
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  id:
                                    "tickerValue-input" +
                                    partPeriodPerformance.rowCount,
                                  placeholder: "Performance value",
                                  disabled:
                                    partPeriodPerformance.actionFlag === "D" ||
                                    _vm.isPPPFetchingData,
                                },
                                domProps: {
                                  value: _vm.numberValueFormatter(
                                    partPeriodPerformance.tickerValue
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.formatInput(
                                      partPeriodPerformance.rowCount,
                                      $event.target.value
                                    )
                                  },
                                  focus: function ($event) {
                                    return $event.target.select()
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Delete",
                                      expression: "'Delete'",
                                    },
                                  ],
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRowById(
                                        partPeriodPerformance.rowCount
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fal", "trash-alt"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Add new part period",
                          expression: "'Add new part period'",
                        },
                      ],
                      staticClass: "add-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.addRow },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "plus"] },
                      }),
                      _vm._v(" New Period "),
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.isValidationFailed || _vm.pppAPIValidationErrorMessage
                      ? _c("p", { staticClass: "invalid-text" }, [
                          _vm._v(
                            "Validation failed. Please fix the following errors: "
                          ),
                          _c(
                            "ul",
                            _vm._l(_vm.listFormErrors, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
          ],
          2
        ),
        _c("div", { staticClass: "modal-footer" }, [
          _vm.pppEntityId
            ? _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { type: "button", disabled: _vm.isPPPFetchingData },
                  on: { click: _vm.save },
                },
                [
                  _vm.isPPPFetchingData
                    ? _c("font-awesome-icon", {
                        staticClass: "loader",
                        attrs: { icon: ["fal", "spinner"], spin: true },
                      })
                    : _vm._e(),
                  _vm._v(" Save "),
                ],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [_vm._v("Close")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }