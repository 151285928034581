import AssetAndMarketValueManagerList from '@/models/AssetAndMarketValueItem';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

class PerformanceItem<TV = number | ''> extends AssetAndMarketValueManagerList<TV, number> {
    performancePeriodTag: string;

    benchmarkId: number | null;

    constructor (
        effectiveDate: string,
        performancePeriodTag: string,
        rowID: number,
        tickerID: number,
        tickerValue: TV,
        type: string,
        isDelete: boolean,
        meta = {
            new: false,
            edited: false,
            show: true,
            isNewColumn: false,
            qIndex: 0,
            isQuarter: false,
        },
        benchmarkId: number | null = null,
    ) {
        super(type, rowID, tickerID, effectiveDate, tickerValue, isDelete);

        meta.qIndex = dayjs(this.effectiveDate).quarter();

        this.effectiveDate = effectiveDate;
        this.performancePeriodTag = performancePeriodTag;
        this.rowID = rowID;
        this.tickerID = tickerID;
        this.tickerValue = tickerValue;
        this.type = type;
        this.isDelete = isDelete;
        this.meta = meta;
        this.benchmarkId = benchmarkId;
    }
}

export default PerformanceItem;
